import * as React from 'react'
import {CalendarBlank, CaretLeft} from 'phosphor-react'
import {eachDayOfInterval, format, isSameDay} from 'date-fns'
import {useStore} from 'common/useStore'
import {
  StopCardContainer,
  Counter,
  StopSummaryWrapper,
  DateHeader,
} from '../routeDetailStyles'
import HistoryTrailButtons from './HistoryTrailButtons'
import StopCardList from './StopCardList'
import CollapsiblePanel from 'common/components/CollapsiblePanel'
import {HistoryTrailPoint} from 'historyTrail/hooks/historyTrailTypes'
import {StopReducerAction} from 'routingAndDispatch/Stops/stopHelper'
import {RoutePlanType} from 'routingAndDispatch/types/typesModule'
interface StopCardProps {
  historyTrailPoints: HistoryTrailPoint[] | undefined
  refetch: () => void
  routeDetailDispatch: (action: StopReducerAction) => void
  showHistoryButtons?: boolean
  scrollToIndex?: number
  routePlan: RoutePlanType
}

const StopCardPanel: React.FC<StopCardProps> = ({
  refetch,
  historyTrailPoints,
  routeDetailDispatch,
  showHistoryButtons = true,
  scrollToIndex = 0,
  routePlan,
}) => {
  const idPlan = routePlan?.idPlan
  const segment = routePlan?.segments && routePlan?.segments[0]
  const planStatus = routePlan?.planStatus
  const planStartDateTime = routePlan?.planStartDateTime
  const planEndDateTime = routePlan?.planEndDateTime
  const userConfig = useStore(state => state.userConfig)
  const [currentScrollDate, setCurrentScrollDate] = React.useState(
    format(
      planStartDateTime || new Date(),
      userConfig?.Date_Format || 'MM/dd/yyyy',
    ),
  )

  const allDates =
    planEndDateTime &&
    planStartDateTime &&
    eachDayOfInterval({start: planStartDateTime, end: planEndDateTime})

  const firstStopOfEachDate: string[] = []

  allDates?.forEach((date: Date) => {
    const firstStop = segment?.stops.find(
      stop =>
        stop.ServiceEndDateTime && isSameDay(stop.ServiceEndDateTime, date),
    )?.idStop
    if (!firstStop) return false
    return firstStopOfEachDate.push(firstStop)
  })

  if (segment !== null) {
    const currentStop = segment?.stops.findIndex(
      stop => stop?.stopStatus === 'New' || stop?.stopStatus === 'InTransit',
    )

    return (
      <CollapsiblePanel
        titleText={'Stops'}
        titleIcon={{
          icon: (
            <Counter>
              <span>
                {segment?.stops.length - 2 >= 0
                  ? segment?.stops.length - 2
                  : '0'}
              </span>
            </Counter>
          ),
          renderWhenClosed: true,
        }}
        width={480}
        iconWhenClosed={<CaretLeft size={16} color={'var(--primary)'} />}
      >
        <StopCardContainer data-cy={'stop-card-panel'}>
          <StopSummaryWrapper>
            {showHistoryButtons && (
              <HistoryTrailButtons
                historyTrailPoints={historyTrailPoints || []}
              />
            )}
            <DateHeader>
              <CalendarBlank weight="bold" size={16} />
              {currentScrollDate}
            </DateHeader>
            <StopCardList
              segment={segment}
              planStatus={planStatus}
              planStartDateTime={planStartDateTime}
              planEndDateTime={planEndDateTime}
              idPlan={idPlan.toString()}
              currentStop={currentStop}
              refetch={refetch}
              currentScrollDate={currentScrollDate}
              setCurrentScrollDate={setCurrentScrollDate}
              historyTrailPoints={historyTrailPoints}
              routeDetailDispatch={routeDetailDispatch}
              showHistoryButtons={showHistoryButtons}
              scrollToIndex={scrollToIndex}
              routePlan={routePlan} //needed for navigating back and forth to order detail
            />
          </StopSummaryWrapper>
        </StopCardContainer>
      </CollapsiblePanel>
    )
  }
  return null
}
export default StopCardPanel
