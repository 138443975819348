import React from 'react'
import ReactDOM from 'react-dom'
import {X, WarningCircle, MapPin} from 'phosphor-react'
import {
  ButtonRow,
  CancelButton,
  CloseArea,
  CloseContainer,
  ErrorMessage,
  Footer,
  FooterRow,
  Header,
  ModalContainer,
  ModalContent,
  ModalHeaderTitle,
  ModalOverlay,
  RequiredText,
  Row,
  SaveButton,
  ScrollableBody,
  SubTitle,
  TabButton,
  TabButtonContainer,
} from './modalStyles'
import {IconContainer, InfoPill, InfoText} from './components'
import {ascendColors} from './GlobalStyles'

type Tab = {
  text: string
  active: boolean
  onClick: () => void
  small: boolean
  color: string
  testId?: string
}

type ModalProps = {
  showDialog: boolean
  className?: string
  title: string | React.ReactElement
  ariaLabel?: string
  subTitle?: string | React.ReactElement
  width?: string
  maxWidth?: string
  height?: string
  disabled?: boolean
  onSave?: () => void
  onDismiss: () => void
  renderFooter?: boolean
  submitButtonText?: string
  showRequiredNote?: boolean
  saveButtonInitialFocus?: boolean
  tabs?: Tab[]
  submitting?: boolean
  errorMessage?: string
  hideCancelButton?: boolean
  hideHeader?: boolean
  showInfoPill?: boolean
  infoPillText?: string
  infoPillBackgroundColor?: string
  viewOnly?: boolean
  showClose?: boolean
  testIdName?: string
  scrollable?: boolean
  children?: React.ReactNode
  closeOnOutsideClick?: boolean
}

const Modal: React.FC<ModalProps> = ({
  showDialog,
  children,
  title = '',
  ariaLabel = 'Ascend Modal',
  subTitle = '',
  width = '400px',
  maxWidth,
  height = 'fit-content',
  disabled = false,
  onSave,
  onDismiss,
  renderFooter = false,
  submitButtonText = '',
  showRequiredNote = false,
  tabs = [],
  errorMessage = '',
  hideCancelButton = false,
  hideHeader = false,
  showInfoPill = false,
  infoPillText = '',
  infoPillBackgroundColor = '',
  viewOnly = false,
  showClose = true,
  testIdName = '',
  scrollable = true,
  closeOnOutsideClick = true,
}) => {
  // const buttonRef = React.useRef<HTMLButtonElement>(null)
  const root = document.getElementById('root')
  if (!showDialog || !root) return null // Hide modal by returning null

  //  render into root div
  return ReactDOM.createPortal(
    <ModalOverlay
      className="modal-overlay"
      role="dialog"
      aria-modal="true"
      aria-labelledby={ariaLabel}
      onClick={closeOnOutsideClick ? onDismiss : () => {}}
    >
      <ModalContainer
        width={width}
        height={height}
        onClick={e => e.stopPropagation()}
        maxWidth={maxWidth}
        data-cy={'ascend-modal'}
      >
        {!hideHeader && (
          <Header className={'modal-header'}>
            <Row>
              <ModalHeaderTitle>{title}</ModalHeaderTitle>

              {showClose && (
                <CloseContainer>
                  {(viewOnly || showInfoPill) && (
                    <InfoPill
                      data-cy={'info-pill'}
                      infoPillBackgroundColor={infoPillBackgroundColor}
                    >
                      <IconContainer>
                        <MapPin color={'#000000'} size={12} weight="fill" />
                      </IconContainer>
                      <InfoText data-cy={'info-pill-text'}>
                        {infoPillText}
                      </InfoText>
                    </InfoPill>
                  )}
                  {(viewOnly || !showInfoPill) && (
                    <CloseArea
                      data-cy={'modal-dismiss'}
                      onClick={onDismiss}
                      data-testid={`${testIdName}-close`}
                    >
                      <X size={20} />
                    </CloseArea>
                  )}
                </CloseContainer>
              )}
            </Row>
            {subTitle ? (
              <Row>
                <SubTitle>{subTitle}</SubTitle>
              </Row>
            ) : null}
            {showRequiredNote && (
              <Row>
                <RequiredText>Required</RequiredText>
              </Row>
            )}
            <Row>
              {tabs && tabs.length > 0 && (
                <TabButtonContainer>
                  {tabs.map((tab, index) => (
                    <TabButton
                      key={index}
                      text={tab.text}
                      active={tab.active || false}
                      onClick={tab.onClick}
                      color={tab.color}
                      data-testid={tab.testId}
                    />
                  ))}
                </TabButtonContainer>
              )}
            </Row>
          </Header>
        )}
        <ModalContent
          className={'modal-content'}
          scrollable={scrollable}
          renderFooter={renderFooter}
        >
          <ScrollableBody id="scrollableModalBody" scrollable={scrollable}>
            {children}
          </ScrollableBody>
        </ModalContent>
        {renderFooter && (
          <Footer className={'modal-footer'}>
            <FooterRow>
              {errorMessage !== '' ? (
                <ErrorMessage>
                  <WarningCircle
                    color={'var(--asc-vermilion)'}
                    size={16}
                    weight="fill"
                  />
                  {errorMessage}
                </ErrorMessage>
              ) : (
                <div></div>
              )}
              <ButtonRow>
                {!hideCancelButton && (
                  <CancelButton
                    data-cy={'modal-cancel'}
                    onClick={onDismiss}
                    data-testid={`${testIdName}-cancel`}
                    name="cancel"
                  >
                    <X size={20} />
                    CANCEL
                  </CancelButton>
                )}

                {onSave && (
                  <SaveButton
                    data-cy={'modal-submit'}
                    // ref={buttonRef}
                    disabled={disabled}
                    onClick={onSave}
                    // loading={submitting}
                    $fontOnPrimary={ascendColors.fontOnPrimary}
                    $fontOnSecondary={ascendColors.fontOnSecondary}
                    data-testid={`${testIdName}-${
                      submitButtonText
                        ? submitButtonText.split(' ')[0].toLowerCase()
                        : 'save'
                    }`}
                  >
                    {submitButtonText ? submitButtonText : 'Save'}
                  </SaveButton>
                )}
              </ButtonRow>
            </FooterRow>
          </Footer>
        )}
      </ModalContainer>
    </ModalOverlay>,
    root,
  )
}

export default Modal
