import * as React from 'react'
import {
  ClusterContainer,
  ClusterMarkerImg,
  ClusterCount,
  ClusterLabel,
} from './mapStyles'
import Alert from 'images/map/warning.svg'
import MaintDue from 'images/map/wrench.svg'
import {ascendColors} from 'common/GlobalStyles'

const ClusterMarker = ({
  cluster,
  pointCount,
}: {
  cluster: any
  pointCount: number
}) => {
  return (
    <ClusterContainer data-cy="clustermarker" data-testid="map-cluster-marker">
      <MarkerCluster count={pointCount} />
      <ClusterCount count={pointCount}>
        {Number(pointCount).toLocaleString()}
      </ClusterCount>
      {cluster.properties.alerts || cluster.properties.maint ? (
        <ClusterLabel>
          {cluster.properties.alerts ? <img src={Alert} /> : <></>}
          {cluster.properties.maint ? <img src={MaintDue} /> : <></>}
        </ClusterLabel>
      ) : null}
    </ClusterContainer>
  )
}
export default ClusterMarker

const largeCircleCount = 999
const mediumCircleCount = 0

const MarkerCluster = ({count}: {count: number}) => {
  const outerSize =
    count > largeCircleCount ? 66 : count > mediumCircleCount ? 50 : 42
  const innerCircle =
    count > largeCircleCount ? 33 : count > mediumCircleCount ? 25 : 21
  const largeCircleRadii = [32, 31.5, 26.5]
  const mediumCircleRadii = [24, 23, 18]
  // const smallCircleRadii = [20, 16.5, 14]
  const radii = count > largeCircleCount ? largeCircleRadii : mediumCircleRadii
  // : count > mediumCircleCount

  // : smallCircleRadii
  return (
    <ClusterMarkerImg $outerSize={outerSize}>
      <circle
        cx={innerCircle}
        cy={innerCircle}
        r={radii[0]}
        fill={ascendColors.customSecondary}
        stroke={ascendColors.customSecondary}
        strokeWidth={count > largeCircleCount ? '2' : '1'}
      />
      <circle
        cx={innerCircle}
        cy={innerCircle}
        r={radii[1]}
        fill={ascendColors.customSecondary}
        stroke={ascendColors.customPrimary}
        strokeWidth={'2'}
      />
      <circle
        cx={innerCircle}
        cy={innerCircle}
        r={radii[2]}
        fill={ascendColors.customSecondary}
        stroke={'white'}
        strokeWidth={'5'}
      />
    </ClusterMarkerImg>
  )
}
