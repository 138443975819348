import styled from 'styled-components/macro'
import {
  QUERIES,
  LEFT_MENU_WIDTH_EXPANDED,
  LEFT_MENU_WIDTH_COLLAPSED,
} from 'common/constants'

export const Container = styled.div`
  grid-area: leftmenu;
  width: 100%;
  height: 100vh;
  background: var(--asc-cultured);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 10px;
  position: relative;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media ${QUERIES.tabletAndUp} {
    background: white;
    border-right: 1px solid var(--asc-coolgray);
    width: ${(p: {collapse: string}) =>
      p.collapse === 'true'
        ? LEFT_MENU_WIDTH_COLLAPSED
        : LEFT_MENU_WIDTH_EXPANDED}px;
  }
`

// export const CompanyName = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 8px;
// `
// export const CompanyNameWithDropDown = styled(CompanyName)`
//   cursor: pointer;
//   &&& i {
//     font-size: 16px;
//   }
// `

export const MenuItem = styled.div<{
  name: string
  isActive: boolean
  open: boolean
  fontOnPrimary: string
  fontOnSecondary: string
}>`
  display: ${p => (p.name === 'hierarchy' ? 'none' : 'flex')};
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  height: 36px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  border-bottom: 1px solid var(--asc-coolgray);
  .active {
    background-color: hsl(0, 0%, 85%);
  }
  span {
    color: var(--primary);
  }
  svg {
    fill: var(--primary);
    color: var(--primary);
    min-width: 16px;
    min-height: 16px;
  }
  @media ${QUERIES.tabletAndUp} {
    display: flex;
    width: 100%;
    gap: 5px;
    background-color: ${props => (props.isActive ? 'var(--primary)' : 'white')};
    border-bottom: none;
    span {
      color: ${props =>
        props.isActive ? props.fontOnPrimary : 'var(--primary)'};
    }
    svg {
      fill: ${props =>
        props.isActive ? 'var(--secondary)' : 'var(--primary)'};
      color: ${props =>
        props.isActive ? 'var(--secondary)' : 'var(--primary)'};
    }
  }
  &:hover {
    background: var(--secondary);
    span {
      color: ${p => p.fontOnSecondary};
    }
    svg {
      fill: var(--primary);
      color: var(--primary);
    }
  }
`

export const MainMenuTitle = styled.span`
  padding-left: 12px;
  white-space: nowrap;
  flex: 1;
`

// export const DisabledMenuTitle = styled(MainMenuTitle)`
//   color: gray;
// `

export const MenuItemContainer = styled.div`
  overflow-y: hidden auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--asc-cultured);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-y: auto;
  overflow-x: hidden;
  @media ${QUERIES.tabletAndUp} {
    background: transparent;
  }
`

export const SubMenuContainer = styled.div<{
  fontOnPrimary: string
  fontOnSecondary: string
  noBackground: boolean
}>`
  .active {
    background-color: ${p =>
      p.noBackground ? 'transparent' : 'var(--primary)'};
    color: ${p => (p.noBackground ? 'var(--primary)' : p.fontOnPrimary)};
  }
`

export const MenuNameAndIcon = styled.span<{
  isActive: boolean
  open: boolean
}>`
  display: flex;
  align-items: center;
  /* color: ${props =>
    props.isActive && props.open ? 'var(--secondary)' : 'var(--primary)'}; */
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 13px;
`

export const CollapseToggle = styled.div<{collapse: string}>`
  justify-content: ${props => (props.collapse === 'true' ? 'center' : 'right')};
  align-items: right;
  display: flex;
  flex: 1;

  svg {
    cursor: pointer;
    :hover {
      fill: var(--primary);
    }
  }
`
export const MenuBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  justify-content: flex-start;
  width: 100%;
  /* border-bottom: 1px solid var(--asc-coolgray); */
  font-size: 16px;
  div {
    min-width: 20px;
    min-height: 60px;
  }
  img {
    width: auto;
    height: auto;
    max-height: 63px;
    max-width: 250px;
  }

  /* svg {
    height: 36px;
    width: 44px;
  } */

  @media ${QUERIES.tabletAndUp} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: none;
  }
  @media ${QUERIES.phoneAndDown} {
    justify-content: space-between;
  }
`

export const BannerLink = styled.a`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

// export const DropdownMenu = styled.div`
//   position: absolute;
//   right: 150px;
//   padding: 10px;
//   top: 50px;
//   z-index: 99;
//   background: #fff;
//   border-radius: 5px;
//   box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
//   -webkit-transition: 0.3s;
//   transition: 0.3s;
//   :hover {
//     box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
//   }
// `
// export const DropdownMenuItem = styled.div`
//   padding: 8px 5px;
//   cursor: pointer;
//   background-color: ${props => (props.current ? 'var(--asc-keylime)' : '#fff')};
//   font-size: 12px;
//   font-weight: bold;
//   :hover {
//     background-color: var(--grey-200);
//   }
// `
export const TooltipLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-left: 6px;
    display: inline;
  }
`
export const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: var(--secondary);
  cursor: pointer;
  min-height: auto !important;
  svg {
    height: 28px;
    width: 28px;
  }
  :hover {
    background: var(--primary);
    svg {
      color: var(--secondary);
      fill: var(--secondary);
    }
  }
`

/*Mobile Menu Components*/
export const MobileMenuContainer = styled.div`
  grid-area: mobileheader;
  display: flex;
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  font-size: 1.2rem;
  padding-right: 10px;
  border-bottom: 1px solid var(--asc-coolgray);
  z-index: 11;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  text-transform: capitalize;
  && .red {
    font-size: 13px;
    padding: 5px;
    margin: 0 5px;
  }
`

export const IconHolder = styled.div`
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  color: var(--primary);
`
export const BottomSection = styled.div`
  padding-top: 10px;
  display: flex;
  overflow-y: auto;
  margin-bottom: 0px;
  overflow-x: hidden;
  padding: 10px 20px;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  background: var(--asc-cultured);
`
export const LogOutButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
  color: var(--primary);
  font-size: 14px;
  svg {
    margin-left: 5px;
  }
`

export const HomeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--secondary);
  cursor: pointer;
  margin-left: 10px;
  :hover {
    background: var(--primary);
    svg {
      color: var(--secondary);
      fill: var(--secondary);
    }
  }
`

export const BrandingImage = styled.div`
  width: 100%;

  background: var(--asc-cultured);
  border-bottom: 1px solid var(--asc-coolgray);
  img {
    min-height: 30px;
    max-height: 75px;
    width: auto;
    height: 100%;
  }
`

export const Title = styled.div`
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
