import * as React from 'react'
import styled from 'styled-components/macro'
import FlyoutItem from './FlyoutItem'
import {LEFT_MENU_PADDING, LEFT_MENU_WIDTH_EXPANDED} from './constants'
import {useStore} from './useStore'

type Item = {
  label: string
  navigateTo?: string
  onClick?: () => void
  restrictedAdminText?: string
}

type Props = {
  items: Item[]
  visible: boolean
  menuTopPosition: number
  setFlyoutHovered: (hovered: boolean) => void
  dataTestId?: string
}

type ContainerProps = {
  visible: boolean
  top: number
}

const Container = styled.div`
  display: ${({visible}: ContainerProps) => (visible ? 'flex' : 'none')};
  position: fixed;
  flex-direction: column;
  left: ${LEFT_MENU_WIDTH_EXPANDED - LEFT_MENU_PADDING - 5}px;
  top: ${({top}: ContainerProps) => top}px;
  width: 196px;
  background-color: #ffffff;
  border: solid 1px var(--asc-coolgray);
  border-radius: 6px;
  overflow: hidden;
  z-index: 10;
`

const FlyoutMenuContainer = ({
  items,
  visible,
  menuTopPosition,
  setFlyoutHovered,
  dataTestId,
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false)
  React.useEffect(() => {
    setFlyoutHovered(isHovered)
  }, [isHovered, setFlyoutHovered])
  const userConfig = useStore(state => state.userConfig)

  return (
    <Container
      visible={visible}
      top={menuTopPosition}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {items.map(
        (item, index) =>
          (!item.restrictedAdminText ||
            !userConfig?.RestrictedAdminAccess ||
            (userConfig.RestrictedAdminAccess &&
              item.restrictedAdminText &&
              userConfig?.RestrictedAdminAccess?.includes(
                item.restrictedAdminText,
              ))) && (
            <FlyoutItem
              key={typeof item.label === 'string' ? item.label : index}
              navigateTo={item.navigateTo}
              label={item.label}
              onClick={item.onClick}
              dataTestId={dataTestId}
            />
          ),
      )}
    </Container>
  )
}
export default FlyoutMenuContainer
