import * as React from 'react'
import styled from 'styled-components/macro'
import {Button} from 'semantic-ui-react'
import {ArrowLeft} from 'phosphor-react'
import {useNavigate} from 'react-router-dom'
import FourOhFourTruck from 'images/fourohfour-truck.png'
import FourOhFourLine from 'images/line.svg'
import GeoforceAscend from 'images/geoforceascendlogo.svg'
const Container = styled.div`
  display: flex;
  width: 100vw;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const StyledH1 = styled.h1`
  font-size: 12rem;
  color: var(--primary);
`

const Logo = styled.img`
  width: 15vw;
  height: auto;
`
const MessageContent = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: 100;
      text-align: center;
    }
  }
`

const StyledButton = styled(Button)`
  &&& {
    display: flex;
    flex-direction: row;
    background: var(--secondary);
    color: var(--primary);
    padding: 15px 20px;
    margin-top: 30px;
    svg {
      margin-right: 10px;
    }
    span {
      font-weight: bold;
      font-size: 13px;
    }
    :hover {
      background: var(--primary);
      color: var(--secondary);
    }
  }
`

const TruckContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  position: absolute;
  img {
    height: 186px;
    width: 310px;
    margin-left: 1180px;
  }
`

const LineContainer = styled.div`
  margin-top: 200px;
  width: 100%;
  height: 8px;
  position: absolute;
  img {
    width: 1485.5px;
  }
`
const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`
const FourOhFour = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <Logo src={GeoforceAscend} />
      <StyledH1>404</StyledH1>
      <MessageContent>
        <h2>
          You&apos;ve reached the end of the road.
          <br />
          The page you requested does not exist or another error occurred.
        </h2>
        <h2>Please check your entry and try again.</h2>
      </MessageContent>
      <StyledButton onClick={() => navigate('map')}>
        <ArrowLeft />
        <span>GO BACK</span>
      </StyledButton>
      <ImageContainer>
        <LineContainer>
          <img src={FourOhFourLine} />
        </LineContainer>
        <TruckContainer>
          <img src={FourOhFourTruck} />
        </TruckContainer>
      </ImageContainer>
    </Container>
  )
}
export default FourOhFour
