import * as React from 'react'
import styled from 'styled-components/macro'
import {
  AddressInformation,
  InputCheckbox,
  InputDropdownOptional,
  InputWithLabel,
  Spinner,
} from '../common/components'
import {
  submitUserProfile,
  alertIncomplete,
  reducer,
  initialState,
} from './userProfileHelper'
import {
  speedOptions,
  fuelOptions,
  temperatureOptions,
  volumeOptions,
  pressureOptions,
  distanceOptions,
} from 'common/helpersUOM'
import {
  timezoneOptions,
  dateFormats,
  timeFormats,
  dSTOptions,
} from 'common/helpersDateTime'
import {Card, Divider} from 'semantic-ui-react'
import useUserDetail from 'users/hooks/useUserDetail'
import usePutUser from 'users/hooks/usePutUser'
import usePutAddress from 'users/hooks/usePutAddress'
import usePostAddress from 'users/hooks/usePostAddress'
import useOpening4 from 'opening/hooks/useOpening4'
import {useStore} from 'common/useStore'
import AlertModal from 'common/components/AlertModal'
import Modal from 'common/Modal'

const Container = styled.div`
  display: flex;
`
const ModalBody = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
`
const Column = styled.div`
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const UserProfile = ({onDismiss, shouldShow}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const userConfig = useStore.getState().userConfig
  const {data, status} = useUserDetail(userConfig?.idUser)
  const {mutateAsync: putUser} = usePutUser()
  const {data: opening4, status: groupStatus} = useOpening4()
  const {mutateAsync: putAddress} = usePutAddress()
  const {mutateAsync: postAddress} = usePostAddress()
  const [showDialog, setShowDialog] = React.useState(false)
  const [showModal, setShowModal] = React.useState(shouldShow)

  const onSave = () => {
    const {First_Name, Last_Name, Email_Address} = state.user
    if (First_Name && Last_Name && Email_Address) {
      dispatch({type: 'setState', data: {submitting: true}})
      submitUserProfile({user: state.user, putUser, putAddress, postAddress})
    } else {
      alertIncomplete()
    }
    onDismiss()
    setShowModal(false)
  }

  React.useEffect(() => {
    if (status === 'success' && data) {
      dispatch({type: 'loadUser', data})
      setShowModal(true)
    } else if (status === 'loading') {
      setShowModal(false)
    }
  }, [data, status])

  React.useEffect(() => {
    if (opening4?.groups && groupStatus === 'success') {
      const groupOptions = opening4?.groups.map(group => {
        return {
          key: group.idGroup,
          value: group.idGroup,
          text: group.Group_Name_With_Number_Of_Assets,
        }
      })
      groupOptions.unshift({
        key: null,
        value: null,
        text: '-- No Group Filter --',
      })
      dispatch({type: 'setGroupOptions', data: {groupOptions}})
    }
  }, [groupStatus, opening4])

  const updateState = e => {
    dispatch({
      type: 'updateUser',
      data: {
        [e.target.name]: e.target.value,
        isDirty: true,
      },
    })
  }

  const onDropdownChange = ({name, value}) => {
    dispatch({
      type: 'updateUser',
      data: {
        [name]: value,
        isDirty: true,
      },
    })
  }

  const setValue = (name, value) => {
    dispatch({
      type: 'updateUser',
      data: {
        [name]: value,
        isDirty: true,
      },
    })
  }

  return (
    <React.Fragment>
      <AlertModal
        showDialog={showDialog}
        onSave={() => {
          setShowDialog(false)
          onDismiss()
        }}
        onDismiss={() => {
          setShowDialog(false)
        }}
        title={`Cancel changes to user profile?`}
        text={'The changes you made will be discarded.'}
        type={'cancel'}
        showDenyButton={true}
        disabled={undefined}
      />
      <Container data-cy="user-profile">
        {status === 'error' && (
          <div>There was an error loading the user profile.</div>
        )}
        {status === 'success' && data ? (
          <Modal
            showDialog={showModal}
            title={'User Profile'}
            width={'75%'}
            renderFooter={true}
            //loading={state.submitting}
            onSave={onSave}
            onDismiss={() => {
              if (state.isDirty) {
                setShowDialog(true) //show alert modal
              } else {
                onDismiss()
                setShowModal(false)
              }
            }}
            submitButtonText="SAVE"
          >
            <ModalBody>
              <Column>
                <InputWithLabel
                  errors={state.errors || {}}
                  name="First_Name"
                  label="First Name"
                  inputProps={{
                    value: state.user.First_Name || '',
                    onChange: updateState,
                  }}
                  required
                />
                <InputWithLabel
                  errors={state.errors || {}}
                  name="Last_Name"
                  label="Last Name"
                  inputProps={{
                    value: state.user.Last_Name || '',
                    onChange: updateState,
                  }}
                  required
                />
                <InputWithLabel
                  errors={state.errors || {}}
                  name="Email_Address"
                  label="Email/User ID"
                  inputProps={{
                    value: state.user.Email_Address || '',
                    onChange: updateState,
                  }}
                  disabled
                />
                <InputDropdownOptional
                  label="Time Zone"
                  name="TZ_Index"
                  options={timezoneOptions}
                  value={state.user.TZ_Index}
                  onChange={onDropdownChange}
                  clearable={false}
                />

                <InputDropdownOptional
                  label="Time Format"
                  options={timeFormats}
                  value={state.user.Time_Format}
                  onChange={onDropdownChange}
                  name="Time_Format"
                  clearable={false}
                />
                <InputDropdownOptional
                  label="Daylight Saving Time"
                  options={dSTOptions}
                  value={state.user.DST_Flag}
                  onChange={onDropdownChange}
                  name="DST_Flag"
                  clearable={false}
                />
                <InputDropdownOptional
                  label="Date Format"
                  options={dateFormats}
                  value={state.user.Date_Format}
                  onChange={onDropdownChange}
                  name="Date_Format"
                  clearable={false}
                />
                <InputWithLabel
                  inputProps={{
                    value: state.user.AccessCode || '',
                    onChange: updateState,
                  }}
                  disabled
                  label="Mobile Device Access Code"
                  name="AccessCode"
                />
              </Column>
              <Column>
                <Card fluid>
                  <Card.Content>
                    <Card.Header>Unit of Measure</Card.Header>
                    <Divider />
                    <Card.Description>
                      <InputDropdownOptional
                        label="Distance Format"
                        options={distanceOptions}
                        value={state.user.Distance_Format}
                        onChange={onDropdownChange}
                        name="Distance_Format"
                        clearable={false}
                      />
                      <InputDropdownOptional
                        label="Speed Format"
                        options={speedOptions}
                        value={state.user.Speed_Format}
                        onChange={onDropdownChange}
                        name="Speed_Format"
                        clearable={false}
                      />
                      <InputDropdownOptional
                        label="Fuel Efficiency Format"
                        options={fuelOptions}
                        value={state.user.FuelEfficiency_Format}
                        onChange={onDropdownChange}
                        name="FuelEfficiency_Format"
                        clearable={false}
                      />
                      <InputDropdownOptional
                        label="Temperature Format"
                        options={temperatureOptions}
                        value={state.user.Temperature_Format}
                        onChange={onDropdownChange}
                        name="Temperature_Format"
                        clearable={false}
                      />
                      <InputDropdownOptional
                        label="Volume Format"
                        options={volumeOptions}
                        value={state.user.Volume_Format}
                        onChange={onDropdownChange}
                        name="Volume_Format"
                        clearable={false}
                      />
                      <InputDropdownOptional
                        label="Pressure Format"
                        options={pressureOptions}
                        value={state.user.Pressure_Format}
                        onChange={onDropdownChange}
                        name="Pressure_Format"
                        clearable={false}
                      />
                    </Card.Description>
                  </Card.Content>
                </Card>
                <InputDropdownOptional
                  label="Group Filter"
                  options={state.groupOptions}
                  value={state.user.Default_Group}
                  onChange={onDropdownChange}
                  placeholder="-- No Group Filter --"
                  name="Default_Group"
                />
                {/* <Langauge /> */}
              </Column>
              <Column>
                <InputCheckbox
                  defaultValue={
                    data?.Asset_Labels !== undefined ? data.Asset_Labels : true
                  }
                  label="Show Asset Names on the Map"
                  name="Asset_Labels"
                  setValue={setValue}
                  labelPosition="after"
                />
                <AddressInformation
                  dispatch={dispatch}
                  address={state.user.Address}
                />
              </Column>
            </ModalBody>
          </Modal>
        ) : (
          status !== 'error' && <Spinner />
        )}
      </Container>
    </React.Fragment>
  )
}
export default UserProfile
