import {useMutation, useQueryClient} from '@tanstack/react-query'
import axios from 'axios'
import Swal from 'sweetalert2'
const postAddress = async address => {
  const {data} = await axios.post(`/addresses`, address)
  return data
}

export default function usePostAddress() {
  const cache = useQueryClient()
  return useMutation(postAddress, {
    onError: (err, variables, previousValue) => {
      console.error(err, variables, previousValue)
      Swal.fire(
        'Error',
        'There was an error saving this user. Please check that the email address is not already in use and try again.',
        'error',
      )
      cache.setQueryData(['addresses'], previousValue)
    },
    // After success or failure, refetch the invitees query
    onSettled: () => {
      cache.invalidateQueries(['addresses'])
    },
  });
}
