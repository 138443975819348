import {useQuery} from '@tanstack/react-query'
import axios from 'axios'

export interface User {
  CustomerType: string
  idCustomerType: number
  CustomerConfigurations: string
  CustomerConfigDisplayDealerships: boolean
  CustomerConfigDisplayCustomers: boolean
  idDCDCustomerGroup: number
  NewUserInfo: string
  Customer_Name: string
  ListOfGroupIds: number[] | null
  ListOfProjectIds: number[] | null
  Created_By_UserName: string
  Updated_By_UserName: string
  Dealer_Name: string
  UserAndRole: string
  AccessCode: string
  Distance_Format: number
  Distance_UOM: string
  Speed_Format: number
  FuelEfficiency_Format: number
  Temperature_Format: number
  Volume_Format: number
  Pressure_Format: number
  IsEnterpriseCustomer: boolean
  CustomPage: string
  AvailableCustomers: AvailableCustomer[]
  ApiPassword: string
  idUser: number
  Email_Address: string
  First_Name: string
  Last_Name: string
  Last_Access: string
  idCustomer: number
  Created_On: string
  Created_By: number
  Role_Id: number
  Active: boolean
  Updated_By: number
  Updated_On: string
  TimeZone: number
  DST_Flag: boolean
  Speed_UOM: string
  Date_Format: string
  Time_Format: string
  idAddress: number
  Default_View: string | null
  Default_Mapview: string | null
  Map_Zoom: string | null
  Default_Group: string | null
  Asset_Labels: boolean
  TimeZone_Default: string | null
  TZ_Index: number
  Is_Dealer: boolean | null
  Dealer_Notes: string | null
  idDealer: number | null
  CRM_ID: number | null
  idVendor: number | null
  CellPhone: string
  WorkPhone: string
  ExternalId: string | null
  Title: string | null
  EmployeeID: string | null
  Department: string | null
  Division: string | null
  RestrictedAdminAccess: boolean | null
  Expiration_Date: string | null
  AEMP_UOM_IsMetric: boolean | null
  MDACPwdToken: string | null
  Is_Support_Admin: boolean | null
  Address: Address
  UserRole: UserRole
}

interface AvailableCustomer {
  idCustomer: number
  Customer_Name: string
  HierarchyPath?: string
  IsTopmostCustomer?: boolean
  IsHierarchyRoot?: boolean
  HierarchyLevel: number
  idCustomerType: number
  ParentCustomers: ParentCustomer[]
}

interface ParentCustomer {
  idCustomer: number
  Customer_Name: string
  idCustomerType: number
  idParentCustomer?: number
  HierarchyLevel: number
  HierarchyName: string | null
  AssetCount: number
  CustomerList: string | null
}

export interface Address {
  idAddress: number
  Address_Line1: string | null
  Address_Line2: string | null
  Address_Line3: string | null
  Locality: string | null
  Region: string | null
  Postal_Code: string | null
  isoCountryCode: string
  idAddress_Type: number | null
  Created_On: string
  Created_By: number
  Latitude: number | null
  Longitude: number | null
}

interface UserRole {
  idUserRole: number
  Role_Label: string
  SortOrder: number
}

const getUserById = async (idUser: number) => {
  const {data} = await axios.get(`/users/${idUser}`)
  return data
}

export default function useUserDetail(idUser: number, shouldFetch = true) {
  return useQuery(['userDetail', idUser], () => getUserById(idUser), {
    enabled: idUser && shouldFetch ? true : false,
  })
}
