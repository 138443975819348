import React from 'react'
import {useNavigate, useLocation, Navigate} from 'react-router-dom'
import {useStore, ORoleType} from 'common/useStore'
import AppLayout from 'common/AppLayout'
import type {User, UserConfig} from './useStore'
import Spinner from 'common/components/Spinner'
import {setToken} from './setToken'
interface Props {
  children: JSX.Element
  showMainMenuHeader: boolean
  title?: string
  restrictedAdminText?: string
}

const ProtectedRoute = ({
  showMainMenuHeader,
  title,
  children,
  restrictedAdminText,
}: Props) => {
  const [validRoute, setValidRoute] = React.useState<undefined | boolean>()

  const user: User = useStore(state => state.user)
  const userConfig: UserConfig | null = useStore(state => state.userConfig)
  const navigate = useNavigate()
  const location = useLocation()
  // console.log('user', user)
  React.useEffect(() => {
    document.title = title ? title : 'Ascend Geoforce'
  }, [title])

  React.useEffect(() => {
    let isRNDCustomer = false

    userConfig?.CustomerConfigurations?.forEach(config => {
      if (
        config.ConfigGroup === 'custommenu'
        // newMenuItems[newMenuItems.length - 1]?.name !== 'eld'
      ) {
        const menus = JSON.parse(config.ConfigSettings) || null
        if (
          menus &&
          menus.maintitle === 'Tranztec' &&
          (userConfig.UserRole.idUserRole === ORoleType.Administrator ||
            (userConfig.UserRole.idUserRole ===
              ORoleType.RestrictedAdministrator &&
              userConfig?.RestrictedAdminAccess?.includes(
                'routing-and-dispatch',
              )))
        ) {
          isRNDCustomer = true
        }
      }
    })

    if (!user.token) {
      const logIn =
        localStorage.getItem('token') || sessionStorage.getItem('token')
      if (logIn) {
        setToken({})
      } else {
        setValidRoute(false)
      }
    } else if (user.role !== null) {
      if (
        user?.role !== ORoleType.TemedaAdministrator &&
        user?.role !== ORoleType.DealerAdministrator
      ) {
        if (
          user?.role !== ORoleType.Administrator &&
          user?.role !== ORoleType.RestrictedAdministrator
        ) {
          if (restrictedAdminText) {
            //regular user trying to access a route only for admins or restricted admins
            navigate('/map')
          } else {
            //regular user trying to access a route open to all users
            setValidRoute(true)
          }
        } else {
          if (
            user?.role === ORoleType.RestrictedAdministrator &&
            restrictedAdminText
          ) {
            if (restrictedAdminText === 'manage-alerts') {
              //restricted admins should have access to manage alerts
            } else if (userConfig?.RestrictedAdminAccess) {
              const allowedRoutes = userConfig.RestrictedAdminAccess.split(',')
              if (allowedRoutes.includes(restrictedAdminText)) {
                //allowed route
                //setValidRoute(true)
              } else {
                navigate('/map')
              }
            } else {
              navigate('/map')
            }
          }
          if (userConfig?.disabledFeatures) {
            userConfig?.disabledFeatures.forEach(feature => {
              if (
                feature === 'dashboards' &&
                location?.pathname.startsWith('/dashboard/')
              ) {
                navigate('/map')
              } else if (
                feature === 'maintenance' &&
                location?.pathname.startsWith('/maintenance')
              ) {
                navigate('/map')
              }
            })
          }
          if (!isRNDCustomer && location?.pathname.startsWith('/routing')) {
            navigate('/map')
          }
          setValidRoute(true)
        }
      } else {
        //Temeda admins or dealers should not access these routes directly
        navigate('/management')
      }
    } else {
      //shouldn't be here go back to login
      navigate('/login')
    }
  }, [
    navigate,
    restrictedAdminText,
    location?.pathname,
    user,
    userConfig?.RestrictedAdminAccess,
    userConfig?.disabledFeatures,
    userConfig?.CustomerConfigurations,
    userConfig?.UserRole.idUserRole,
  ])

  if (validRoute === true) {
    return (
      <AppLayout showMainMenuHeader={showMainMenuHeader}>{children}</AppLayout>
    )
  } else {
    //set requested url into session storage and redirect after logging in
    if (validRoute === false) {
      const path = location?.pathname || ''
      if (path && path !== '/map') {
        // console.log('setting requestedUrl')
        sessionStorage.setItem('requestedUrl', path)
      }
      return <Navigate to="/" />
    }
    //validRoute is undefined show spinner until resolved
    return <Spinner />
  }
}

export default ProtectedRoute
