import * as React from 'react'
import styled from 'styled-components/macro'
import {Button, Form, List} from 'semantic-ui-react'
import {QUERIES} from 'common/constants'
import LoginImage from 'images/geoforceLoading.jpg'
import {InputWithLabel} from 'common/components'
import {Eye, EyeSlash, X} from 'phosphor-react'
import {submitResetPassword} from 'changePassword/changePasswordHelper'
import GeoforceAscend from 'images/geoforceascendlogo.svg'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`

const LeftPanel = styled.div`
  padding: 50px;
  width: 100%;
  @media ${QUERIES.tabletAndUp} {
    width: 460px;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  h3 {
    margin-bottom: 40px;
  }
  &&& {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      width: 460px;
      .field {
        width: 100%;
        .labeled {
          .label {
            cursor: pointer; /*icons look clickable*/
          }
        }
      }
      input[type='text'],
      input[type='password'] {
        border: 1px solid var(--asc-coolgray);
        padding: 10px 15px;
        background-color: white;
        font-size: 17px;
        border-style: solid;
        border-radius: 5px;
        :focus {
          border: 1px solid var(--tertiary);
          box-sizing: border-box;
          box-shadow: 0px 0px 6px 1px var(--asc-coolgray);
        }
      }
      label {
        /* margin-top: 8px; */
        color: black;
        font-size: 15px;
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }
      .ui.checkbox {
        margin-top: 3px;
      }
      a {
        color: var(--primary);
        font-weight: bold;
        :hover {
          color: var(--secondary);
          span {
            color: var(--primary);
          }
        }
      }
    }
  }
`

const RightPanel = styled.div`
  display: flex;
  width: 0;
  @media ${QUERIES.tabletAndUp} {
    width: 100%;
  }
  border: none;
`

const Logo = styled.img`
  width: auto;
  max-height: 130px;
  margin-top: 30px;
`

const UpdateButton = styled(Button)`
  &&& {
    padding: 10px 5px;
    background-color: ${({disabled}) =>
      disabled ? 'var(--asc-platinum)' : 'var(--primary) !important'};
    color: ${({disabled}) => (disabled ? 'var(--asc-dimgray)' : 'white')};
    width: 175px;
    height: 50px;
    font-weight: bold !important;
    font-size: 13px;
    border-radius: 6px;
    :hover {
      background: var(--secondary) !important;
      color: var(--primary) !important;
    }
  }
`

// const StyledLabel = styled.label`
//   &&&& {
//     color: black;
//     font-size: 15px;
//     font-weight: bold;
//   }
// `

const ReturnToLoginLink = styled.a`
  display: flex;
  flex-direction: row;
  padding: 10px 5px;
  color: var(--primary);
  height: 50px;
  font-weight: 700 !important;
  font-size: 13px;
  align-items: center;
  div {
    margin-left: 5px;
    margin-top: 2px;
    margin-right: 15px;
  }
  :hover {
    svg,
    div {
      color: var(--secondary);
    }
  }
`

const HelpMessage = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  margin-top: 20px;
  span {
    font-weight: bold;
  }
  a {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    color: var(--primary);
    font-weight: bold;
    margin-top: 5px;
    flex-wrap: wrap;
    span {
      font-weight: normal;
      margin-right: 4px;
    }
  }
  h1 {
    font-size: 20px;
    font-weight: normal;
    color: black;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
  }
`

const LoginHelpContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const PasswordRequirementsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--asc-sonicsilver);
  margin-bottom: 10px;
`
const PasswordRequirementsLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
`
const PasswordRequirementsList = styled.div`
  margin-left: 15px;
  margin-bottom: 30px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: 50px;
`

const LengthListItem = styled(List.Item)`
  color: ${props =>
    props.reasonNotValid.includes('length') && props.reasonNotValid !== ''
      ? 'var(--asc-vermilion)'
      : props.reasonValid.includes('length') && props.reasonValid !== ''
      ? 'var(--green)'
      : 'var(--asc-sonicsilver)'};
`
const UppercaseListItem = styled(List.Item)`
  color: ${props =>
    props.reasonNotValid.includes('uppercase') && props.reasonNotValid !== ''
      ? 'var(--asc-vermilion)'
      : props.reasonValid.includes('uppercase') && props.reasonValid !== ''
      ? 'var(--green)'
      : 'var(--asc-sonicsilver)'};
`

const NumberListItem = styled(List.Item)`
  color: ${props =>
    props.reasonNotValid.includes('number') && props.reasonNotValid !== ''
      ? 'var(--asc-vermilion)'
      : props.reasonValid.includes('number') && props.reasonValid !== ''
      ? 'var(--green)'
      : 'var(--asc-sonicsilver)'};
`
const PasswordsMatchLabel = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--asc-vermilion);
  width: 100%;
  margin-left: 30px;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 25%;
  align-content: center;
  align-items: center;
`

const urlParam = name => {
  var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(
    window.location.href,
  )
  if (results === null) {
    return null
  } else {
    return results[1] || 0
  }
}

const ResetPassword = () => {
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [reasonValid, setReasonValid] = React.useState('')
  const [reasonNotValid, setReasonNotValid] = React.useState('')
  const [isMatch, setIsMatch] = React.useState(null)

  var token = urlParam('token')
  var type = urlParam('type')
  const [newPasswordVisible, setNewPasswordVisible] = React.useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    React.useState(false)
  const [passwordValid, setPasswordValid] = React.useState(
    reasonValid.includes('length') &&
      reasonValid.includes('uppercase') &&
      reasonValid.includes('number'),
  )

  const onSubmit = () => {
    return submitResetPassword(newPassword, token, type)
  }

  const checkValidation = () => {
    const regExpUpperCase = /[A-Z]/
    let reasonInvalid = ''
    let reasonValid = ''
    if (newPassword.length < 8 || newPassword.length > 32) {
      reasonInvalid = reasonInvalid + 'length,'
    } else {
      reasonValid = reasonValid + 'length,'
    }
    if (!regExpUpperCase.test(newPassword)) {
      reasonInvalid = reasonInvalid + 'uppercase,'
    } else {
      reasonValid = reasonValid + 'uppercase,'
    }
    if (!/\d/.test(newPassword)) {
      reasonInvalid = reasonInvalid + 'number,'
    } else {
      reasonValid = reasonValid + 'number,'
    }
    setReasonValid(reasonValid)
    setReasonNotValid(reasonInvalid)
    setPasswordValid(
      reasonValid.includes('length') &&
        reasonValid.includes('uppercase') &&
        reasonValid.includes('number'),
    )
    if (confirmPassword !== '') {
      checkMatch()
    }
  }

  const checkMatch = input => {
    if (newPassword === input) {
      setIsMatch(true)
    } else {
      setIsMatch(false)
    }
  }
  return (
    <Container>
      <LeftPanel>
        <LogoContainer>
          <Logo src={GeoforceAscend} />
        </LogoContainer>
        <h3>Reset Password</h3>
        <Form onSubmit={onSubmit}>
          {/* <Form.Field>
            <StyledLabel htmlFor="forgot-email">Email</StyledLabel>
            <input
              type="text"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
              data-cy="forgot-email"
              id="forgot-email"
            />
          </Form.Field> */}
          <InputWithLabel
            data-cy="resetpassword-newpassword"
            value={newPassword}
            onChange={e => setNewPassword(e.currentTarget.value)}
            onBlur={checkValidation}
            label="New Password"
            name="newPassword"
            suffixIcon={
              newPasswordVisible ? (
                <Eye onClick={() => setNewPasswordVisible(false)} size={20} />
              ) : (
                <EyeSlash
                  onClick={() => setNewPasswordVisible(true)}
                  size={20}
                />
              )
            }
            type={newPasswordVisible ? 'text' : 'password'}
          />
          <PasswordRequirementsContainer>
            <PasswordRequirementsLabel>
              Passwords must:
            </PasswordRequirementsLabel>
            <PasswordRequirementsList>
              <List bulleted>
                <LengthListItem
                  reasonNotValid={reasonNotValid}
                  reasonValid={reasonValid}
                >
                  be between 8 and 32 characters long
                </LengthListItem>
                <UppercaseListItem
                  reasonNotValid={reasonNotValid}
                  reasonValid={reasonValid}
                >
                  contain at least 1 UPPERCASE letter
                </UppercaseListItem>
                <NumberListItem
                  reasonNotValid={reasonNotValid}
                  reasonValid={reasonValid}
                >
                  contain at least 1 number
                </NumberListItem>
              </List>
            </PasswordRequirementsList>
          </PasswordRequirementsContainer>
          <InputWithLabel
            data-cy="resetpassword-confirmpassword"
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.currentTarget.value)
              checkMatch(e.currentTarget.value)
            }}
            //onBlur={checkMatch}
            label="Confirm New Password"
            name="confirmPassword"
            suffixIcon={
              confirmPasswordVisible ? (
                <Eye
                  onClick={() => setConfirmPasswordVisible(false)}
                  size={20}
                />
              ) : (
                <EyeSlash
                  onClick={() => setConfirmPasswordVisible(true)}
                  size={20}
                />
              )
            }
            type={confirmPasswordVisible ? 'text' : 'password'}
          />
          {isMatch !== null && !isMatch ? (
            <PasswordsMatchLabel>Passwords do not match</PasswordsMatchLabel>
          ) : (
            <></>
          )}
          <ButtonsContainer>
            <ReturnToLoginLink href="/">
              <X weight="bold" /> <div>CANCEL</div>
            </ReturnToLoginLink>
            <UpdateButton
              primary
              fluid
              size="large"
              type="submit"
              disabled={!passwordValid || !isMatch}
            >
              UPDATE PASSWORD
            </UpdateButton>
          </ButtonsContainer>
          <LoginHelpContainer>
            <HelpMessage>
              <span>Need help? </span>
              <a href={'mailto:customer.support@ascendfleet.com'}>
                <span>Email us at </span>
                {'customer.support@ascendfleet.com'}
              </a>
            </HelpMessage>
          </LoginHelpContainer>
        </Form>
      </LeftPanel>
      <RightPanel>
        <ImageContainer>
          <img src={LoginImage}></img>
        </ImageContainer>
      </RightPanel>
    </Container>
  )
}
export default ResetPassword
