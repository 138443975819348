import {CaretLeft, CaretRight} from 'phosphor-react'
import * as React from 'react'
import styled from 'styled-components/macro'

type StyledProps = {isOpen: boolean; width?: number}

const Container = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  overflow: hidden;
  height: 100%;
  width: ${({isOpen, width}) => (isOpen ? width + 'px' : '56px')};
  min-width: ${({isOpen, width}) => (isOpen ? width + 'px' : '56px')};
  right: 0;
  border-left: solid 1px var(--asc-coolgray);
  border-right: solid 1px var(--asc-coolgray);
  transition: 0.3s;
  ${({isOpen}) => !isOpen && 'cursor: pointer;' + 'padding-top: 20px;'}
  z-index:7; //hides time line now in route time table
`
const TitleContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: relative;
  width: ${({isOpen}) => (isOpen ? '100%' : '400px')};
  cursor: ${({isOpen}) => isOpen && 'pointer'};
  align-items: ${({isOpen}) => isOpen && 'center'};
  justify-content: ${({isOpen}) => isOpen && 'space-between'};
  padding: ${({isOpen}) => isOpen && '20px'};
  transform: ${({isOpen}) => (isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};
  transform-origin: 99px 111px;
  :hover {
    background-color: ${({isOpen}) => isOpen && 'var(--asc-cultured)'};
  }
`
const TitleText = styled.span<StyledProps>`
  font-weight: ${({isOpen}) => (isOpen ? '600' : '700')};
  font-size: ${({isOpen}) => (isOpen ? '16px' : '13px')};
  text-transform: ${({isOpen}) => !isOpen && 'uppercase'};
  white-space: ${({isOpen}) => !isOpen && 'nowrap'};
`
const PanelContainer = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
`

const BottomIconWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
`

interface Props {
  titleText?: string
  titleIcon?: {icon: React.ReactNode; renderWhenClosed: boolean}
  bottomIconWhenClosed?: React.ReactNode
  iconWhenClosed?: React.ReactNode
  children?: React.ReactNode
  className?: string
  onLeft?: boolean
  defaultOpen?: boolean
  width?: number
}

const CollapsiblePanel: React.FC<Props> = ({
  titleText,
  titleIcon,
  bottomIconWhenClosed,
  children = undefined,
  className = '',
  iconWhenClosed,
  onLeft = false,
  defaultOpen = true,
  width = 305,
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen)

  const handlePanelOpen = () => {
    !isOpen && setIsOpen(true)
  }
  const handlePanelClose = () => {
    isOpen && setIsOpen(false)
  }

  return (
    <Container
      data-cy={'collapsible-panel'}
      onClick={handlePanelOpen}
      isOpen={isOpen}
      width={width}
      className={className}
    >
      {!isOpen && iconWhenClosed}
      <TitleContainer onClick={handlePanelClose} isOpen={isOpen}>
        <Row>
          <TitleText isOpen={isOpen}>{titleText}</TitleText>
          {titleIcon &&
            (isOpen || titleIcon.renderWhenClosed) &&
            titleIcon.icon}
        </Row>
        {isOpen && !onLeft && <CaretRight size={16} color={'var(--primary)'} />}
        {isOpen && onLeft && <CaretLeft size={16} color={'var(--primary)'} />}
      </TitleContainer>
      {isOpen && <PanelContainer>{children}</PanelContainer>}
      {bottomIconWhenClosed && !isOpen && (
        <BottomIconWrapper>{bottomIconWhenClosed}</BottomIconWrapper>
      )}
    </Container>
  )
}
export default CollapsiblePanel
